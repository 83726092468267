import React from "react"
import { Container } from "semantic-ui-react"

import BackgroundImage from "gatsby-background-image"
const Background = ({ img }) => {
  return (
    <Container fluid content textAlign="center">
      <BackgroundImage
        fluid={img.childImageSharp.fluid}
        style={{ height: "55vh" }}
      >
        <span></span>
      </BackgroundImage>
    </Container>
  )
}

export { Background }
